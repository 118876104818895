import * as React from 'react';
import { useEffect,useState,useRef } from 'react';
import Dropzone from "react-dropzone";
import {IFace } from '../@types/Face';
import UploadService from '../services/upload.service';
import FaceComponent from '../components/Face.Component';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';

const Faces = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [faces,popFaceData] = React.useState<IFace[]>([]);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);

    //file upload
    const MAX_SIZE = 5242880;
    const [prevfile, setPrevFile] = useState<any>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [currentFile, setCurrentFile] = useState<any>(undefined);
    const [progress, setProgress] = useState(0);

    const uploadService = UploadService.getInstance();

    useEffect(()=>{
      //document.title = "webcamspider.com";
      setProgress(0);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
       return () => { if(prevfile!==undefined) URL.revokeObjectURL(prevfile.preview)};
       //setShow(true);      
  }, []); // empty array means only once

  //file upload
  const onDrop = (files:any) => {

    resetUpload();

    if (files.length > 0 && files[0].size > MAX_SIZE) {
      searchAgain();
      setErrorMsg(`file too big. Your file is ${files[0].size} KB, Max is ${MAX_SIZE} KB`);
      return;
    }

    if (files.length > 0) {
      setSelectedFiles(files);
      let currentFile = files[0];
      setCurrentFile(currentFile);
      setPrevFile(() => Object.assign(currentFile, {
        preview: URL.createObjectURL(currentFile)
      }
    ));
    }
  };

  const upload = () => {

    setLoading(true);

    uploadService.uploadFile(currentFile, (event:any) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    }).then(response => { 
      if(response.length >0 && IsFace(response[0])){
        popFaceData(response);
        }else{
          setErrorMsg(handleHttpError(response));
      } 
    }).catch(error => {
      setErrorMsg(handleHttpError(500));
      console.log(error)
    }).then(() =>{
          setLoading(false);
          setFileUploaded(true);
          var resultsDiv = document.getElementById('results');
          if(resultsDiv)
            resultsDiv.scrollIntoView({behavior: 'smooth', block: 'center'})
    });
  }

  const resetUpload = () => {
    setProgress(0);
    setErrorMsg("");
    setFileUploaded(false);
    popFaceData([]);
   }   
   
  const searchAgain = () => {
    var dzDiv = document.getElementById('dropzone_container');
    if(dzDiv)
      dzDiv.scrollIntoView({behavior: 'smooth', block: 'start'})
    resetUpload();
    setSelectedFiles([]);
    let currentFile = undefined;
    setCurrentFile(currentFile);
   }  

  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };
 return (
  <div>
      <section>
            <div className='pageHeader'>
              <h1>Cam girl face search engine</h1>
              <div><b><i>Find your favourite cam girl model again!</i></b></div>
            </div>
            <div className='info' id='info'>
                To find your favourite cam girl model again or even similar looking cam girls simply upload an image of her face and press 'Start Search'. 
                For best results use a cropped full frontal face image of just her face exluding any distractions or additional faces in the background. 
                We use the latest AI enable face recognition libraries to return the most accurate face matches to the girl you are searching for.
                We also regularly crawl the most popular cam girl sites in order to find the faces of new girls and models so you can find your favourite 
          </div>
      </section>
    <div id='dropzone_container'>
    {!fileUploaded && (
      <div>
        {selectedFiles[0] ? (
            <div onClick={searchAgain} style={{maxWidth: '800px',margin:'auto',textAlign:'right'}}>
              <img src={'images/reload.png'} alt="Reload" title='Reload' width={'25px'} style={{cursor:'pointer'}}/>
          </div> 
          ):(
            <div style={{maxWidth: '800px',height:'25px',margin:'auto'}}></div>
          )}
      <Dropzone onDrop={onDrop} multiple={false} onFileDialogOpen={resetUpload} onDragOver={resetUpload}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {selectedFiles[0] && selectedFiles[0].name ? (
                <div>          
                    {prevfile && (
                      <div className='dropzone-thumbsOutter'>
                        <div className='dropzone-thumbsContainer'>
                          <div className='dropzone-thumb'>
                            <div className='dropzone-thumbInner'>
                              <img
                                  src={prevfile.preview} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}         
                  <div className="selected-file">
                    {selectedFiles && selectedFiles[0].name}
                  </div>
                </div>
              ) : (
                <div className='dropzone-file-types'>
                  <div>Drop face image here to upload (.jpg, .png, .gif, .webp)</div>
                  <br/>
                  or
                  <br/><br/>
                </div>
              )}
              {currentFile ===undefined && (
                <div>
                  <div>
                    <button className="btn btn-success" onClick={onDrop}>
                          Browse for face image
                        </button>
                    </div>
                  <div className='dropzone-file-size'>
                    <div>Max face image file size: 5MB</div>
                  </div>
                </div>
              )}
            </div>
            <div style={{display: 'inline-block'}}>
              <aside className="file-selected-wrapper">
                {currentFile && (
                <button
                  className="btn btn-success"
                  disabled={loading}
                  onClick={upload}
                >
                  Start Search
                </button>
                )}
              </aside>
            </div>
            <div style={{display: 'inline-block'}}>{loading && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>          
          </section>
        )}
      </Dropzone>
    </div>
    )}
    </div>
      {loading && (
        <div className="progress mx-auto mt-3" style={{width: "75%"}}>
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}
        <div id="results">
        {fileUploaded && (
          <div>
            <div><br/><h3>Search results for image:</h3></div>
          </div>
        )}
        {!fileUploaded && errorMsg && (
              <div className="alert alert-danger message" role="alert">
                {errorMsg}
              </div>
         )}
        {fileUploaded && (
          <div>
                      <div className='resultsThumbsOutter'>
                        <div className='thumbsContainer'>
                          <div className='thumb'>
                            <div className='thumbInner'>
                              <img
                                  src={prevfile.preview}
                                  // Revoke data uri after image is loaded
                                  onLoad={() => { URL.revokeObjectURL(prevfile.preview) }} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
             {!errorMsg && faces.length>0 &&  (
              <div className="resultsMessage">
                {faces.length} possible matches found:
              </div>
              )}
             {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter'>
              {!errorMsg && faces.length>0 &&  faces.map((face: IFace) => (
                <FaceComponent key={face.name}  face={face} imgWidth='150' imgHeight='150'/>
                    ))    
                } 
            </div>
          </div>
        )}
          <div className='info'>
                For more info on getting the best facial recognition results see our<br/>
                'Cam girl facial recognition search guide' at the foot this page.
          </div> 
        {fileUploaded && (
            <div style={{margin:'30px'}}>
            <button
                  className="btn btn-success"
                  onClick={searchAgain}>
                   Search again
                </button>
            </div> 
            )} 
          </div> 
          <div className='info' style={{marginTop:'30px'}}>
            <section>
              <h2>Cam girl face search results explainer:</h2>
                <div className='explainer'>
                    <picture>
                      <source type="image/webp" srcSet ="images/explainer.webp"/>
                      <source type="image/jpg" srcSet ="images/explainer.jpg"/>
                      <img src="images/best_face.jpg" alt="Cam girl search results explainer" style={{marginTop:'30px'}} width={'330px'} height={'354px'}/>
                    </picture>
                    <div>
                      <ol>
                        <li>Web site the cam girl performs on.</li>
                        <li>% likely it's a match. 100% is a perfect match.</li>
                        <li>Gender, this is not 100% reliable.*</li>
                        <li>The last time our crawler saw the cam girl online</li>
                      </ol>
                    </div>
                </div>     
            </section>
          </div>  
          <div style={{marginTop: "50px"}}>
          <section>
          <h2>Popular cam girl face searches:</h2>
          <div className='resultsThumbsOutter' style={{marginBottom: '100px'}}>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/CintyaLeinner')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/CintyaLeinner/nJaH6KbZRCSRl+0lfimO1fswOhjlCCbRs88PnepRsOOfjS+I.jpg'}`} alt='Cintya Leinner' title='Cintya Leinner' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Cintya Leinner</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/ArianaSantana')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/ArianaSantana/IFFAsiJPFxbHYofUX+xYhOflehNpYHkPUptDgZb19CGjO+P+.jpg'}`} alt='Ariana Santana' title='Ariana Santana' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Ariana Santana</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/MiaPersy')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                        <img src={`${'images/MiaPersy/szODfJqCDEUrLlaJxIKXmWA7jPrS1nim3vKuF8dYcm1Sq7g3.jpg'}`} alt='Mia Persy' title='Mia Persy' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>         
                        <span>Mia Persy</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/GabyPastori')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/GabyPastori/FN7OgItZHlDXO7wny2HkwafAWG1vkUj9yjiE+HmUvKpc4ckS.jpg'}`} alt='Gaby Pastori' title='Gaby Pastori' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Gaby Pastori</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/JessicaPortman')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/JessicaPortman/xJpLH2K8B0OlGJqrE7ZIv0rEMIdi5jjqgtGzRJIGg9YnoAwK.jpg'}`}  alt='Jessica Portman' title='Jessica Portman' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Jessica Portman</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/GeorgiaBotero')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/GeorgiaBotero/KXduaPGdTJmgWEY5ftZSlQxz1VRjkkhb6wzxMHDNT3sCOXTR.jpg'}`} alt='Georgia Botero' title='Georgia Botero' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Georgia Botero</span>
                      </div>  
                </div>
            </div>
          </div>
          </section>
        </div>
        <div className='info'>
          <section>
          <h2>Cam girl facial recognition search guide</h2>
          <br/>
          <p>First of all, the cam girl image you upload to search must be 5MB in size or less and be in Jpeg, Png, Gif or Webp format. 
          To get the best cam girl search results try to use the best quality full face image you have for the cam girl you are searching for.
          Try to use an image where the face is facing forward and as square on in the image as possible. 
          Try to use the most up to date image you have for the cam girl you are searching for.
            Below is a good example of an image that is good for searching for a cam girl face search.
          </p>
          <div className='tips_image'>
            <picture>
                <source type="image/webp" srcSet ="images/best_face.webp"/>
                <source type="image/jpg" srcSet ="images/best_face.jpg"/>
                <img src="images/best_face.jpg" alt="Try to use an image where the face is facing forward and as square on in the image as possible"/>
              </picture>
          </div>           
          <br/>
          <p>Avoid images that have more than one face in them like the image below.<br/>Cam girl face searches can only be performed at present one face at a time.</p>
          <div className='tips_image'>
            <picture>
                <source type="image/webp" srcSet ="images/two_faces.webp"/>
                <source type="image/jpg" srcSet ="images/two_faces.jpg"/>
                <img src="images/best_face.jpg" alt="Avoid images that have more than the face of the cam girl you are searching for in them"/>
              </picture>
          </div>
          <br/>
          <p>Avoid using side on or side profile face images of the cam girl you are searching for.<br/>This cam girl face search does not work with side profile face images.</p>
          <div className='tips_image'>
            <picture>
                <source type="image/webp" srcSet ="images/side_face.webp"/>
                <source type="image/jpg" srcSet ="images/side_face.jpg"/>
                <img src="images/best_face.jpg" alt="Avoid using side on or side profile face images of the cam girl you are searching for"/>
              </picture>
          </div>
          <br/>
          <p>Avoid using images where the cam girl's face is a very small part of the image. These are very unlikely to produce any facial recognition search matches.
           Use an cam girl face image with the largest highest quality full frontal face you have.</p>
           <div className='tips_image'>
            <picture>
                <source type="image/webp" srcSet ="images/small_face.webp"/>
                <source type="image/jpg" srcSet ="images/small_face.jpg"/>
                <img src="images/best_face.jpg" alt="Avoid using images where the cam girl's face is a very small part of the image"/>
              </picture>
          </div>
          </section>
        </div>
    </div>
  );
};

  export default Faces;
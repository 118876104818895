import {IFace } from '../@types/Face';
import { useEffect,useState } from 'react';

type IProps = {
  face: IFace;
  imgWidth: string,
  imgHeight: string
};

const FaceComponent: React.FC<IProps> = ( props:IProps) => {

  const [currentPersonUrl, setPersonUrl] = useState('');
  const [currentThumbnailUrl, setThumbnailUrl] = useState('');
  const [lastSeenstr, seLastSeenstr] = useState('');
  const [siteICO, setSiteICO] = useState('');
  const [genderICO, setGenderICO] = useState('');

  useEffect(()=>{
    seLastSeenstr(date_TO_String(new Date(props.face.lastSeen)));
    setThumbnailUrl(props.face.thumbnailUrl);
    setPersonUrl(props.face.personUrl);
    setSiteICO('images/' + props.face.siteCode + '.ico');
    setGenderICO('images/' + props.face.gender + '.png');
}, []); // empty array means only once

  const handleImageError = () => {
    setThumbnailUrl('images/unknown.gif');
   }  

   function date_TO_String(date_Object: Date): string {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      date_Object.getDate() +
      "/" +
      (date_Object.getMonth() + 1) +
      "/" +
      +date_Object.getFullYear();

    return date_String;
  }

  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };

return (
  
    <div className="results">
      <div  role="link" onClick={() => openInNewTab(currentPersonUrl)}>
            <div className='thumb'>
              <div className='thumbInner'>
                <img  onError={handleImageError} src={`${currentThumbnailUrl}`}  width={props.imgWidth} height={props.imgHeight}/>
              </div>     
            </div>
            <div>              
              <span>
                <img src={`${siteICO}`} alt={`${props.face.site}`} title={`${props.face.site}`}  width={16} height={16}/>
                &nbsp;&nbsp;{props.face.name}&nbsp;
                {Number(props.face.score) > 0 && (   
                props.face.score + '%'
                )}
                &nbsp;<img src={`${genderICO}`} alt={`${props.face.gender}`} title={`${props.face.gender}`}  width={16} height={16}/>
              </span><br/>
              <span>Last seen: {lastSeenstr}</span>
            </div>  
    </div>
  </div>
  );
}
export default FaceComponent;
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState,useEffect } from 'react';
import Main from './components/Main.component';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {Modal, Button} from 'react-bootstrap';
import './App.css';

function App() {
      //modal
      const [show, setShow] = useState(false);
      const handleClose = () => {
        window.sessionStorage.setItem('agreedToSexualContentPopup','true')
        setShow(false);
      }
      useEffect(()=>{ 

        var val = window.sessionStorage.getItem('agreedToSexualContentPopup');
        if((val === undefined || val == null) || val!='true')
          setShow(true);

      }, []); // empty array means only once

      function redirectAway(){
        window.location.replace('https://www.google.com')
      };

  return (
      <div className="App">
        <Link to={`/`}>
        <div className="topMenu">
          <div style={{display: 'inline-block',marginTop:0}}><h5>CamgirlCrawler</h5></div>
          <div style={{display: 'inline-block',marginTop:0}}>
          <picture>
            <source media="(max-width: 600px)" srcSet="images/logo_sm.gif" width={'61px'} height={'63px'}  />
            <source media="(min-width: 600px)" srcSet="images/logo.gif" width={'81px'} height={'84px'} />
            <img src="/images/logo.gif" width={'81px'} height={'84px'}  alt="CamgirlCrawler is a face recognition based cam girl model search engine"/>
          </picture>
          </div>         
        </div>
        </Link>
        <Main /> 
        <div className='info' style={{padding:'50px',marginTop:'10px'}}>
            <section>
              <h2>Privacy Policy</h2>
              <p>Our privacy policy is really very simple. We don't collect or store any of your data, or track your usage in any way. 
                <br/>We only use one cookie to record that you have understood we don't use cookies.
              </p>
            </section>
          </div>
            <div style={{marginTop:'10px'}}>Problems?<br/> 
              <p><a href="mailto:admin@camgirlcrawler.com">admin@camgirlcrawler.com</a></p>
          </div>
          <div style={{margin:'50px'}}>
            <section>
              <p> * This site is dedicated to cam girl searches. However, it is not always possible for our crawler bot to accurately determine gender of the web cam performer.  
              </p>
            </section>
          </div>      
      <Modal show={show} className='modal-lg' aria-label="Sexual Content Warning!" aria-labelledby='scw'>
        <Modal.Header>
            <Modal.Title>Sexual Content Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{textAlign:'center'}}>
          <p  id='scw' >This web page may include images that may contain sexually explicit material<br/>that might not be suitable or offensive for some viewers<br/>
          <br/>
          <b>Enter ONLY if you are at least 18 years old</b>
          </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-danger' onClick={handleClose}>I accept and I am at least 18 years old</Button>
          <Button className='"btn btn-warning' onClick={redirectAway}>Nope, get me out of here!</Button>
        </Modal.Footer>
        </Modal>
        <CookieConsent
                location="bottom"
                buttonText="Got It!"
                cookieName="camgirlcrawler"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
              >
                This website does not use any cookies, other than to record you understand this message.{" "}
        </CookieConsent>
      </div>
  );
}
export default App;